import { notNull } from "@warrenio/utils/notNull";
import z from "zod";
import type { GqFieldFilter, GqFilterValue } from "./FieldConfig.tsx";
import { ColumnFilterInputType } from "./graphql.gen/graphql.ts";

/** Converts an empty filter to `null`. */
export function removeEmptyFilter(value: GqFilterValue | null): GqFilterValue | null {
    if (value == null || isEmptyFilter(value)) {
        return null;
    }
    return value;
}

export function isEmptyFilter(value: GqFilterValue): boolean {
    return value.value == null && value.value2 == null && value.values == null;
}

//#region Serialization of filters to concise URL format
type SerializedQuickFilter =
    | [field: string, value: string]
    | [field: string, values: string[]]
    | [field: string, value: string | null, value2: string | null];

export const SerializedQuickFilterSchema = z.union([
    z.tuple([z.string(), z.string()]),
    z.tuple([z.string(), z.string().nullable(), z.string().nullable()]),
    z.tuple([z.string(), z.array(z.string())]),
]);

export function serializeQuickFilter(filter: GqFieldFilter): SerializedQuickFilter | undefined {
    const { field, type, value, value2, values } = filter;
    if (type === ColumnFilterInputType.List) {
        return [field, notNull(values)];
    } else if (type === ColumnFilterInputType.Range) {
        return [field, value ?? null, value2 ?? null];
    } else if (value != null) {
        return [field, value];
    }
    return undefined;
}

export function deserializeQuickFilter(f: SerializedQuickFilter): GqFieldFilter {
    const [field, value, value2] = f;
    if (Array.isArray(value)) {
        return { field, type: ColumnFilterInputType.List, values: value };
    } else if (f.length === 3) {
        return { field, type: ColumnFilterInputType.Range, value: value ?? undefined, value2: value2 ?? undefined };
    } else {
        return { field, type: ColumnFilterInputType.Exact, value: value ?? undefined };
    }
}
