import { isArray } from "remeda";
import { link } from "../components/Action.tsx";
import { serializeQuickFilter } from "./filterUtils.ts";
import {
    BillingAccountOrderFields,
    ColumnFilterInputType,
    LoadBalancerOrderFields,
    ServicePackageOrderFields,
    UserOrderFields,
    VmOrderFields,
} from "./graphql.gen/graphql.ts";

type SearchId = string | string[];

export function buildTableDetailSearch(field: string, id: SearchId) {
    const detailId: string = isArray(id) ? id[0] : id;
    return {
        ...buildTableExactSearch(field, id),
        detailId,
    };
}

export function buildTableExactSearch(field: string, value: SearchId) {
    if (isArray(value) && value.length === 1) {
        value = value[0];
    }

    return {
        filters: [
            serializeQuickFilter(
                isArray(value)
                    ? {
                          field,
                          type: ColumnFilterInputType.List,
                          values: value,
                      }
                    : {
                          field,
                          type: ColumnFilterInputType.Exact,
                          value,
                      },
            ),
        ],
    };
}

export function adminBillingAccountLink(id: SearchId) {
    return link({
        to: "/admin/accounts",
        search: buildTableDetailSearch(BillingAccountOrderFields.Id, id),
    });
}

export function adminUserLink(id: SearchId) {
    return link({
        to: "/admin/users",
        search: buildTableDetailSearch(UserOrderFields.Id, id),
    });
}

/** XXX: Broken since base-operator does not support EXACT filtering on UUID fields */
export function adminVmLink(id: SearchId) {
    return link({
        to: "/admin/virtual_machines",
        search: buildTableDetailSearch(VmOrderFields.Uuid, id),
    });
}

export function adminHostLink(id: string) {
    return link({
        to: "/admin/hosts",
        // XXX: This is not a real GraphQL table so only the `detailId` is used by the actual page
        search: { detailId: id },
    });
}

export function adminServicePackageLink(id: SearchId) {
    return link({
        to: "/admin/service_packages",
        search: buildTableDetailSearch(ServicePackageOrderFields.Uuid, id),
    });
}

export function adminLoadBalancerLink(id: SearchId) {
    return link({
        to: "/admin/load_balancers",
        search: buildTableDetailSearch(LoadBalancerOrderFields.Uuid, id),
    });
}
